import store from '@/store'
const title = store.getters.title
export const operatingSystem = [
  { name: 'IOS', id: '0' },
  { name: '安卓', id: '1' }
]
export const auditStsList = [
  { name: '开启', id: 'A' },
  { name: '关闭', id: 'S' }
]
export const columns = [
  {
    dataIndex: '',
    title: '状态',
    fixed: true,
    scopedSlots: { customRender: 'sts' },
    width: 70,
    align: 'left'
  },
  {
    dataIndex: 'name',
    title: '应用',
    fixed: true,
    scopedSlots: { customRender: 'place' },
    align: 'left',
    width: 250
  },
  {
    dataIndex: 'userName',
    title: '应用所属',
    fixed: true,
    width: 130,
    align: 'left'
  },
  {
    dataIndex: '',
    title: '广告位数',
    fixed: true,
    width: 100,
    scopedSlots: { customRender: 'adPlaceNum' },
    align: 'left'
  }
]
export const rightColumns = [
  {
    dataIndex: '',
    title: '操作',
    fixed: 'right',
    scopedSlots: { customRender: 'action' },
    align: 'left',
    width: 65
  }
]
export const columnTitle = [
  {
    dataIndex: 'income',
    title: '预估收益',
    sorter: true,
    scopedSlots: { customRender: 'income' },
    desc: `预估收益=SUM(广告源人工填写的eCPM价格*${title}统计的展示/1000)`,
    align: 'left'
  },
  {
    dataIndex: 'dau',
    title: 'DAU',
    sorter: true,
    scopedSlots: { customRender: 'dau' },
    desc: '每天打开应用的独立用户数',
    align: 'left'
  },
  {
    dataIndex: 'arpu',
    title: '人均收益',
    sorter: true,
    scopedSlots: { customRender: 'arpu' },
    desc: 'ARPDAU = 收益 / DAU',
    align: 'left'
  },
  {
    dataIndex: 'aipau',
    title: '展示/DAU',
    sorter: true,
    scopedSlots: { customRender: 'aipau' },
    desc: '人均展示次数',
    align: 'left'
  },
  {
    dataIndex: 'permeability',
    title: '渗透率',
    sorter: true,
    scopedSlots: { customRender: 'permeability' },
    desc: '每天观看过广告的用户占比，渗透率=DEU/DAU',
    align: 'left'
  },
  {
    dataIndex: 'requestFilledRate',
    title: '流量填充率',
    sorter: true,
    scopedSlots: { customRender: 'requestFilledRate' },
    desc: `应用向${title}发送请求后，响应成功的占比`,
    align: 'left'
  }
]
export const column = [
  {
    dataIndex: '',
    title: '状态',
    scopedSlots: { customRender: 'sts' },
    align: 'left'
  },
  {
    dataIndex: 'name',
    title: '应用',
    scopedSlots: { customRender: 'place' },
    align: 'left'
  },
  {
    dataIndex: '',
    title: '广告位数',
    scopedSlots: { customRender: 'adPlaceNum' },
    align: 'left'
  }
]

// 根据一个数组的顺序排序另一个数组
export function sortList (sortList, dragArr) {
  const arr = dragArr.map((item) => {
    return item.key
  })
  sortList.sort((a, b) => {
    return arr.indexOf(a.prop) - arr.indexOf(b.prop)
  })
  return sortList
}
